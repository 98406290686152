import React, { useState, useEffect} from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Menu from '@mui/material/Menu'
import { ExternalLink, Menu as MenuIcon, X } from 'react-feather'
import Container from '@mui/material/Container'
import Avatar from '@mui/material/Avatar'
import Tooltip from '@mui/material/Tooltip'
import MenuItem from '@mui/material/MenuItem'
import { inject, observer } from 'mobx-react'
import { Link } from 'react-router-dom'
import WrkXFmlyLogo from '../../assets/svgs/wrkxfmlyLogo'
import { LogOut, User } from 'react-feather'
import { routeAssociations } from '../../utils/routes'
import { styled } from '@mui/material/styles'
import { withRouter } from '../../utils/customHoc'

const StyledMenu = styled(props => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'right'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right'
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    left: '0px !important',
    borderRadius: 0,
    marginTop: { xs: theme.spacing(0), sm: theme.spacing(1) },
    width: '100%',
    maxWidth: '100%',
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px'
  }
}))

const HeaderMenu = props => {
  const { userStore, location, resourceStore } = props
  const { role, initials } = userStore
  let logoLink = '/dashboard'
  if (role === 'teacher' || role === 'facilitator') {
    logoLink = '/classes/current'
  } else if (role === 'curator') {
    logoLink = '/reviews/in-curatorial-review'
  }
  const [anchorElNav, setAnchorElNav] = React.useState(null)
  const [anchorElUser, setAnchorElUser] = React.useState(null)
  const [matchingPath, setMatchingPath] = useState(location.pathname)
  const [linkTo, setLinkTo] = React.useState('')

  const handleOpenNavMenu = event => {
    setAnchorElNav(event.currentTarget)
  }
  const handleOpenUserMenu = event => {
    setAnchorElUser(event.currentTarget)
  }

  const handleCloseNavMenu = () => {
    setAnchorElNav(null)
  }

  const handleCloseUserMenu = () => {
    setAnchorElUser(null)
  }

  const selectedNavRoute = () => {
    if (matchingPath !== location.pathname)
    {
      setMatchingPath(location.pathname)
    }
    for (const [key, value] of Object.entries(routeAssociations)) {
      if (value.filter(v => matchingPath.includes(v)).length > 0)
      {
        if (['appadmin', 'curator'].includes(role) && key === 'appSupport')
        {
          continue
        }
        else
        {
          return key
        }
      }
    }
  }

  const navigationMenuItems = [
    {
      title: 'Your Classes',
      link: '/classes/current',
      pathname: 'classes',
      roles: ['teacher', 'facilitator']
    },
    {
      title: 'Classes',
      link: '/classes/current',
      pathname: 'classes',
      roles: ['appadmin']
    },
    {
      title: 'Your Reviews',
      link: '/reviews/in-curatorial-review',
      pathname: 'reviews',
      roles: ['curator']
    },
    {
      title: 'Reviews',
      link: '/reviews/in-submission-review',
      pathname: 'reviews',
      roles: ['appadmin']
    },
    {
      title: 'Schools',
      link: '/schools',
      pathname: 'schools',
      roles: ['appadmin']
    },
    {
      title: 'Users',
      link: '/users/students',
      pathname: 'users',
      roles: ['appadmin']
    },
    {
      title: 'Your Projects',
      link: '/projects',
      pathname: 'projects',
      roles: ['student']
    },
    {
      title: 'Resources',
      link: '/resources',
      pathname: 'resources',
      roles: ['student', 'teacher', 'facilitator', 'appadmin', 'curator']
    },
    {
      title: 'National Collection',
      link: '/national-photos',
      pathname: 'nationalPhotos',
      roles: ['student', 'teacher', 'facilitator', 'appadmin', 'curator']
    },
    {
      title: 'App How-To',
      link: linkTo,
      pathname: 'appSupport',
      roles: ['student', 'teacher', 'facilitator']
    }
  ]

  const accountManagementMenuItems = [
    {
      title: 'Support/Feedback',
      link:
        'https://noteforms.com/forms/wrkxfmly-support-and-feedback-lkyogw?bb1df2d1-b424-4b81-9cb6-de9289878555=2023-09-05&83e7ffb2-7c4b-4abe-9cfc-a9621025fef4=Teacher',
      icon: <ExternalLink size={17} strokeLinecap='square' strokeWidth='3px' />,
      roles: ['teacher'],
      target: '_blank'
    },
    {
      title: 'Support/Feedback',
      link:
        'https://noteforms.com/forms/wrkxfmly-support-and-feedback-lkyogw?bb1df2d1-b424-4b81-9cb6-de9289878555=2023-09-05&83e7ffb2-7c4b-4abe-9cfc-a9621025fef4=Teaching+Artist',
      icon: <ExternalLink size={17} strokeLinecap='square' strokeWidth='3px' />,
      roles: ['facilitator'],
      target: '_blank'
    },
    {
      title: 'Account Details',
      link: '/account-details',
      icon: <User size={17} strokeLinecap='square' strokeWidth='3px' />,
      roles: ['student', 'teacher', 'facilitator', 'appadmin', 'curator']
    },
    {
      title: 'Log Out',
      link: '/logout',
      icon: <LogOut size={17} strokeLinecap='square' strokeWidth='3px' />,
      roles: ['student', 'teacher', 'facilitator', 'appadmin', 'curator']
    }
  ]

  useEffect(() => {
    resourceStore.categories.map(resource =>
      {
        if(resource.fields.Name === 'Web App Guides')
        {
          setLinkTo(`/resource-category/${resource.id}/${(resource.fields.Name.replace(/\s+/g, '-'))}`)
        }
      })
  }, [resourceStore])

  return (
    <AppBar
      position='fixed'
      sx={{
        backgroundColor: '#2d2d2d'
      }}
    >
      <Container maxWidth='xl'>
        <Toolbar disableGutters sx={{ px: { md: 3 } }}>
          <Box
            component='a'
            href={logoLink}
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' }
            }}
          >
            <WrkXFmlyLogo fill='#fff' logoHeight='30px' />
          </Box>

          <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size='large'
              aria-label='account of current user'
              aria-controls='menu-appbar'
              aria-haspopup='true'
              onClick={handleOpenNavMenu}
              color='inherit'
              sx={{ p: { xs: 2, sm: 1 } }}
            >
              {anchorElNav ? <X /> : <MenuIcon />}
            </IconButton>
            <StyledMenu
              id='menu-appbar'
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left'
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left'
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
                width: '100%',
                maxWidth: '100%'
              }}
              PaperProps={{
                sx: {
                  width: '100%'
                }
              }}
              MenuListProps={{
                sx: {
                  backgroundColor: '#2d2d2d',
                  minWidth: '200px',
                  width: '100%'
                }
              }}
            >
              {navigationMenuItems.map(page =>
                page.roles.includes(role) ? (
                  <Link to={page.link} key={`page-title-link-${page.title}`}>
                    <MenuItem
                      key={page.title}
                      sx={{
                        backgroundColor: '#2d2d2d',
                        ':hover': {
                          backgroundColor: '#505050'
                        },
                        marginLeft: { xs: 2, sm: 3 }
                      }}
                    >
                      <Typography textAlign='center' sx={{ color: '#fff' }}>
                        {page.title}
                      </Typography>
                    </MenuItem>
                  </Link>
                ) : null
              )}
              {accountManagementMenuItems.map(
                setting =>
                  setting.roles.includes(role) && (
                    <MenuItem
                      key={setting.title}
                      component='a'
                      href={setting.link}
                      target={setting?.target}
                      sx={{
                        backgroundColor: '#2d2d2d',
                        ':hover': {
                          backgroundColor: '#505050'
                        },
                        marginLeft: { xs: 2, sm: 3 }
                      }}
                    >
                      <Box
                        component='span'
                        sx={{ mr: '15px', color: '#fff', svg: { verticalAlign: 'middle' } }}
                      >
                        {setting.icon}
                      </Box>
                      <Typography textAlign='center' sx={{ color: '#fff' }}>
                        {setting.title}
                      </Typography>
                    </MenuItem>
                  )
              )}
            </StyledMenu>
          </Box>
          <Box
            component='a'
            href={logoLink}
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              width: '90%'
            }}
          >
            <WrkXFmlyLogo fill='#fff' logoHeight='30px' />
          </Box>
          <Box sx={{ display: 'flex', position: 'absolute', right: '0', maxWidth: '80%' }}>
            <Box sx={{ mx: 4, display: { xs: 'none', md: 'flex' } }}>
              {navigationMenuItems.map(page =>
                page.roles.includes(role) ? (
                  <Link
                    key={page.title}
                    to={page.link}
                    style={{
                      color: 'white',
                      backgroundColor: selectedNavRoute() === page.pathname ? '#8959a2' : '',
                      borderRadius: '0px',
                      display: 'block',
                      fontWeight: '600',
                      margin: '0px 5px',
                      padding: '15px',
                      height: '50px',
                      ':hover': {
                        color: '#fff',
                        backgroundColor: '#505050'
                      }
                    }}
                  >
                    {page.title}
                  </Link>
                ) : null
              )}
            </Box>

            <Box>
              <Tooltip>
                <IconButton
                  onClick={handleOpenUserMenu}
                  sx={{ p: 0, position: 'relative', top: { xs: '0px', sm: '0px', md: '10px' } }}
                >
                  <Avatar
                    sx={{
                      height: '30px',
                      width: '30px',
                      backgroundColor: '#8959a2',
                      fontSize: '14px',
                      fontWeight: '600'
                    }}
                  >
                    {initials}
                  </Avatar>
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ display: { xs: 'none', md: 'block' }, mt: '45px', minWidth: '200px' }}
                id='menu-appbar'
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
                MenuListProps={{
                  sx: {
                    backgroundColor: '#2d2d2d',
                    minWidth: '200px'
                  }
                }}
              >
                {accountManagementMenuItems.map(
                  setting =>
                    setting.roles.includes(role) && (
                      <MenuItem
                        key={setting.title}
                        component={Link}
                        target={setting?.target}
                        to={setting.link}
                        sx={{
                          backgroundColor: '#2d2d2d',
                          ':hover': {
                            backgroundColor: '#505050'
                          }
                        }}
                      >
                        <Box
                          component='span'
                          sx={{ mr: '15px', color: '#fff', svg: { verticalAlign: 'middle' } }}
                        >
                          {setting.icon}
                        </Box>
                        <Typography textAlign='center' sx={{ color: '#fff' }}>
                          {setting.title}
                        </Typography>
                      </MenuItem>
                    )
                )}
              </Menu>
            </Box>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  )
}

export default inject('userStore', 'resourceStore')(observer(withRouter(HeaderMenu)))
